import React from 'react';
import ReactDOM from 'react-dom';
import 'bulma/css/bulma.min.css';
import './index.css';
import App from './App';
import Amplify from "aws-amplify";
//import config from "./config";
import * as serviceWorker from './serviceWorker';


// // Attempting to create a runtime global variable that can be updated at runtime
// window.$runtimeConfig = {
//   "api": { 
//     "invokeUrl": "https://5bltcq602h.execute-api.us-west-2.amazonaws.com/prod"
//   },
//   "cognito": {
//     "REGION": "us-east-2",

//     "USER_POOL_ID": "us-east-2_WhrqIjjGg",
//     "APP_CLIENT_ID": "67ha4dhqeha6ql718jg10tt5i7"
//   }
// };

let config=window['runtimeConfig'];


Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  }
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
